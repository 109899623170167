@media only screen and (min-width: 860px) {
  html {
    scroll-behavior: smooth !important;
  }
}

select option {
  color: black;
}

body {
  scroll-padding-top: 100px !important;
}

div {
  scroll-margin-top: 100px !important;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('../public/fonts/Cocogoose-Pro-Regular.woff2');
}
